import type { Ref } from 'vue';
import type { Page } from '@/shared/types/model';
import { applyTemplateVariables } from '@shared/utils/applyTemplateVariables';
import { useSiteStore } from '@/stores/site';
import { COOKIE, getNamedCookie } from '@/utils/cookie';
import { reloadSessionId } from '@/utils/session';
import { GTM, GA4, Facebook } from '@/utils/integrations';
import { Payment } from '@/services/payment';
import { getBaseUrl } from '@/utils/getBaseUrl';
import { useDocLinks } from './useDocLinks';
import { getPackagePrices, getPackagePeriodsInfo } from '@/shared/elements/common/payment/utils';
import { PackagePaymentType, TrialPackageType } from '@/shared/elements/common/payment/types';

export function usePaymentSuccess(page: Ref<Page>) {
  const route = useRoute();
  const { t: $t } = useI18n();
  const { slug } = getBaseUrl(route?.fullPath);
  const cookiePath = `/${slug}`;
  const subscriberId = useCookie(COOKIE.SUBSCRIBER_ID, { sameSite: 'none', secure: true, path: cookiePath });
  const isUserAlreadySubscribed = useCookie(COOKIE.USER_ALREADY_SUBSCRIBED, { sameSite: 'none', secure: true, path: cookiePath });
  const { isPreview, applicationLinks, siteData, packages } = storeToRefs(useSiteStore());

  const pageData = ref({
    applicationLinks: applicationLinks.value,
    hiddenConditionalRefs: getHiddenConditionalRefs(),
    isSuccessMode: !isUserAlreadySubscribed.value,
    docLinks: useDocLinks(),
    paymentDetails: {},
    hasEpinPackage: getHasEpinPackageValue(),
    epinCode: getEpinCodeValue()
  });

  const subscriptionDetail = ref();
  const domain = globalThis?.location?.origin;
  Payment.BASE_PATH = `${domain}/api/payment`;

  function paymentGTMSuccess() {
    GTM.push({
      event: 'success',
      successType: 'Payment'
    });

    GA4.gtag('event', 'success', {
      successType: 'Payment'
    });
  }

  function paymentSuccessGTMpageView() {
    GA4.gtag('event', 'page_view', {
      page_title: document?.title || '',
      page_location: window.location.pathname || '',
      previous_page_path: new URL(document.referrer || window.location.origin)?.pathname || '',
    });
  }

  async function paymentIntegrationCompleted() {
    const app = subscriptionDetail.value?.application || {};
    const packageData = subscriptionDetail.value?.package || {};
    const utmSource = JSON.parse(getNamedCookie(COOKIE.UTM) || '{}')?.utm_source || '';

    const {
      transaction_id: transactionId = ' ',
      currency = ' ',
      price = '0.00',
      provider_name: paymentMethod = ''
    } = subscriptionDetail.value?.transaction?.[0] || {};

    paymentGTMSuccess();

    const gtmObj = {
      transaction_id: transactionId,
      value: price,
      currency,
      coupon: ' ',
      subscriber_id: subscriberId.value || '',
      items: [
        {
          item_id: packageData.packageId,
          item_name: packageData.name,
          affiliation: utmSource,
          coupon: ' ',
          index: 1,
          item_brand: app.name || ' ',
          item_category: packageData.periodType || ' ',
          price,
          quantity: 1
        }
      ]
    }

    GTM.push({ event: 'purchase', payment_method: paymentMethod, ecommerce: gtmObj });
    GA4.gtag('event', 'purchase', { payment_method: paymentMethod, ...gtmObj, });

    Facebook.purchase({
      value: price,
      currency,
      orderID: transactionId,
      eventID: transactionId,
    });
  }

  function paymentSuccessGTMClick(event: any) {
    GTM.push({
      event: 'customClick',
      clickCategory: 'Thank You Page',
      clickName: event.target.innerText
    });

    GA4.gtag('event', 'customClick', {
      clickCategory: 'Thank You Page',
      clickName: event.target.innerText
    });
  }

  function loadGTMClickEvents() {
    if (isPreview.value) return;
    const buttons = document.querySelectorAll('.tw-element-button, a[href]:not([href="#"]):not([href=""])');

    for (const button of buttons) {
      button.addEventListener('click', paymentSuccessGTMClick);
    }
  }

  function clearSubscriberIdCookie() {
    subscriberId.value = '';
  }

  function clearIsUserAlreadySubscribedCookie() {
    isUserAlreadySubscribed.value = ''
  }

  function getHiddenConditionalRefs() {
    const registerTypeRefs = siteData?.value?.registerType === 'phoneNumber' ? ['mailSpecificElement']: ['phoneSpecificElement'];
    const userAlreadySubscribedRefs = isUserAlreadySubscribed.value ? ['successElement'] : ['errorElement'];
    return [...registerTypeRefs, ...userAlreadySubscribedRefs];
  }

  function getHasEpinPackageValue() {
    const hasEpinPackageOnFlowPackages = packages.value?.some(item => item.packageType === PackagePaymentType.EPIN);
    if (isPreview.value) return hasEpinPackageOnFlowPackages;
    const hasEpinCode = !!siteData?.value?.epinDetails?.epinCode;
    return hasEpinCode;
  }

  function getEpinCodeValue() {
    return siteData?.value?.epinDetails?.epinCode;
  }

  function getPaymentAmountText(packageItem: Record<string, any>) {
    const { trialPrice, price } = getPackagePrices(packageItem);
    const { period:periodCount, periodType, trialPeriod:trialPeriodCount, trialPeriodType } = getPackagePeriodsInfo(packageItem);
    const period = $t(`generate.periods.${periodType}`, {
      count: periodCount,
    });
    const trialPeriod = $t(`generate.periods.${trialPeriodType}`, {
      count: trialPeriodCount,
    });
    const localizationVars = {
      price,
      period,
      trialPrice,
      trialPeriod,
    };
    switch(packageItem?.packageType) {
      case PackagePaymentType.CONSUMABLE:
        return $t("generate.paymentSuccess.paymentDetails.packagePriceInfo.consumable", localizationVars);
      case PackagePaymentType.EPIN:
        return $t("generate.paymentSuccess.paymentDetails.packagePriceInfo.epin", localizationVars);
      case PackagePaymentType.SUBSCRIPTION:
        switch (packageItem?.trialPackageType) {
          case TrialPackageType.FREE_TRIAL:
            return $t("generate.paymentSuccess.paymentDetails.packagePriceInfo.freeTrial", localizationVars);
          case TrialPackageType.STARTING_PRICE:
            return $t("generate.paymentSuccess.paymentDetails.packagePriceInfo.startingPrice", localizationVars);
          case TrialPackageType.NO:
            return $t("generate.paymentSuccess.paymentDetails.packagePriceInfo.noTrial", localizationVars);
          default:
            return "";
        }
      default:
        return "";
    }
  }

  function setPaymentSuccessDetails(subscriptionDetailResult: Record<string, any>) {
    if (Array.isArray(subscriptionDetailResult)) return;
    const { application, package:packageItem, transaction } = subscriptionDetailResult || {};
    const { purchase_date = '', expire_date = '', provider_name:paymentMethod = '' } = transaction?.[0] || {};
    const paymentAmount = getPaymentAmountText(packageItem);
    const details = {
      product: application?.name || '',
      paidOn: purchase_date,
      expiresOn: expire_date,
      paymentMethod,
      paymentAmount,
      packageType: packageItem?.packageType,
      trialPackageType: packageItem?.trialPackageType || ''
    }
    pageData.value.paymentDetails = details
  }

  async function initEventsAndIntegrations() {
    loadGTMClickEvents();
    return Payment.getSubscriptionDetail().then((response) => {
      if (response.meta?.errorCode) return;
      subscriptionDetail.value = response.result;
      setPaymentSuccessDetails(response.result);
      paymentIntegrationCompleted();
    });
  }

  function init() {
    setTimeout(() => {
      nextTick(async () => {
        if (!isPreview.value) {
          applyTemplateVariables({
            USER_MAIL: subscriberId.value || '',
            PHONE_NUMBER: subscriberId.value ? `+${subscriberId.value}` : '',
            PACKAGE_INSTRUCTIONS: siteData?.value?.epinDetails?.usage?.[0]?.content || ''
          });
          if (!isUserAlreadySubscribed.value) await initEventsAndIntegrations();
          reloadSessionId(isPreview.value, cookiePath);
        }
        clearSubscriberIdCookie();
        clearIsUserAlreadySubscribedCookie();
        paymentSuccessGTMpageView();
      });
    }, 100);
  }

  onMounted(() => {
    init();
  });

  return { page, pageData };
}
